var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"99%","icon":"mdi-cellphone-dock","color":"primary","title":"Productos","text":"Resumen de Products"}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.products,"page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Filtrar por nombre: "+_vm._s(_vm.search)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":"search","placeholder":"Escribe el texto","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.updatedAt))+" ")]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.source)+" ")])]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.openDetails(item)}}},[_vm._v(" Detalles ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(" Aún no cuentas con productos ")])]},proxy:true}])}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.products.length ? _vm.products.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.products.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.detailsModal),callback:function ($$v) {_vm.detailsModal=$$v},expression:"detailsModal"}},[_c('product-details',{attrs:{"product":_vm.currentProduct}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }